
import {
  CoinbaseWalletConnector,
  MetamaskConnector,
  // Mumbai,
  // Polygon,
  Arbitrum
} from '@usedapp/core';
import { WalletConnectV2Connector } from '@usedapp/wallet-connect-v2-connector'

export const connectors = {
  injected: new MetamaskConnector(),
  walletConnect: new WalletConnectV2Connector({
    projectId: "179a27c0cbb60b68019e61c8ef654221",
    chains: [
      // Mumbai,
      Arbitrum,
      // Polygon
    ],
    rpcMap: {
      // [Mumbai.chainId]: "https://rpc.ankr.com/polygon_mumbai",
      [Arbitrum.chainId]: "https://arb1.arbitrum.io/rpc",
      // [Polygon.chainId]: "https://rpc.ankr.com/polygon",
    }
  }),
  coinbaseWallet: new CoinbaseWalletConnector()
}
