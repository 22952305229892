import {
  // Mumbai,
  Arbitrum
} from '@usedapp/core';

export const MAIN_URL = "https://app-games.jellygames.io";

// export const GAMES_URL = "http://localhost:3000";
export const GAMES_URL = "https://games.jellygames.io";

export const PARTNER_URL = "https://jellybet.io";

export const COIN_FLIP_GAME = "coinflip";

export const MEGA_DICE_GAME = "megadice";

export const LIMBO_GAME = "limbo";

export const WHEEL_GAME = "luckyWheel";

export const USDT_CURRENCY = "USDT";

export const FREE_WHEEL_GAME = "freeWheel";

// export const POLYGON_MUMBAI_NETWORK = "polygonMumbai";
export const ARBITRUM_NETWORK = "arbitrumOne";

export const LIVE_TABLE = "live";

export const MY_TABLE = "my-games";

// export const DEFAULT_CHAIN_ID = Mumbai.chainId;
export const DEFAULT_CHAIN_ID = Arbitrum.chainId;

// export const DEFAULT_USDT_ADDRESS = "0x8c68ED92BEC67c770b649C64e077f2F0D35F88fB"; /// connected to Mumbai Chain
export const DEFAULT_USDT_ADDRESS = "0xFd086bC7CD5C481DCC9C85ebE478A1C0b69FCbb9"; /// connected to Arbitrum Chain

export const BALANCE_DIVIDER = Math.pow(10, 18);

export const IERC20_METADATA_ABI = [
  // funcs
  "function decimals() external view returns (uint8)",
]

export const ESC_KEY = "Escape";

export const METAMASK_CONNECTOR = "injected";

export const WALLET_CONNECTOR = "walletConnect";

export const COINBASE_CONNECTOR = "coinbaseWallet";

// export const DEMO_DECIMALS = 18;
export const DEMO_DECIMALS = 6;

// export const DEFAULT_DECIMALS = 18;
export const DEFAULT_DECIMALS = 6;
